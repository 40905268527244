import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Socials from '../../socials';
import { useNavigate } from 'react-router-dom';

CompletedSales.propTypes = {
    data: PropTypes.array,
};

function CompletedSales({ data }) {
const navigate = useNavigate();
  const handleItemClick = (slug) => {
    navigate(`/project_details/${slug}`);
  };
    return (
        <section className="tf-section project_4">
            <div className="container">
                <div className="row">
                    <div className="col-md-11">
                        <div className="tf-title">
                            <h2 className="title">
                                Completed Sales
                            </h2>
                        </div>
                    </div>
                    
                    <div className="col-md-11">
                        <div className="project-box-style3_wrapper">
                            {data.map((item, index) => (
                                <div key={item.id || index} className="project-box-style3" onClick={() => handleItemClick(item.slug)}>
                                    <div className="header_project">
                                        <div className="image">
                                            <img src={item.project_logo} alt="" />
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="td td1">
                                            <h5>{item.title}</h5>
                                        </div>
                                        <div className="td td2">
                                            <p>Total raise</p>
                                            <p>{item.platform_raise || "N/A"}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CompletedSales;