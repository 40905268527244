import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faXTwitter,
  faTelegramPlane,
  faMediumM,
  faDiscord,
  faYoutube,
  faRedditAlien,
  faInstagram,
  faGithub,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

const iconMap = {
  website_url: faGlobe,
  twitter_url: faXTwitter,
  telegram_url: faTelegramPlane,
  medium_url: faMediumM,
  discord_url: faDiscord,
  youtube_url: faYoutube,
  reddit_url: faRedditAlien,
  instagram_url: faInstagram,
  github_url: faGithub,
  linkedin_url: faLinkedin,
};

function Socials({ socials = {}, active = true }) {
  return (
    <ul className="social">
      {Object.entries(socials).map(([key, url]) => {
        if (url && iconMap[key]) {
          return (
            <li key={key}>
              <div className="icon-wrapper">
                <a href={active ? url : '#'} target="_blank" rel="noopener noreferrer" onClick={e => !active && e.preventDefault()}>
                  <FontAwesomeIcon icon={iconMap[key]} size="3x" />
                </a>
              </div>
            </li>
          );
        }
        return null;
      })}
    </ul>
  );
}

Socials.propTypes = {
  socials: PropTypes.object.isRequired,
  active: PropTypes.bool
};

export default Socials;