// App.js
import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import AOS from 'aos';
import '../src/assets/fonts/font-awesome.css';
import Header from './components/header';
import Footer from './components/footer';
import HomeOne from './pages/HomeOne';
import ProjectDetails from './pages/ProjectDetails';
import Loader from './components/loader';
import PageFaqs from './pages/PageFaqs';
import SubmitIGO from './pages/SubmitIGO';
import Roadmap from './pages/Contact';
import Contact from './pages/Contact';

function App() {

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    AOS.init({ duration: 2000 });


    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch('https://api.infinitypad.com/projects/', {
          method: 'GET',
        });
        const result = await response.json();
        setData(result.completed)
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching data:', error);
        // setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<HomeOne data={data} />} />
        <Route path="/project_details/:slug" element={<ProjectDetails />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;