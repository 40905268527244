import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Socials from '../components/socials';
import Loader from '../components/loader';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

function ProjectDetails() {
    const { slug } = useParams();
    const [project, setProject] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0); // Use index for selected tab
    const dataTab = [
        { id: 'about', title: 'About' },
        { id: 'team', title: 'Team' },
        { id: 'offerings', title: 'Offerings' },
    ];

    useEffect(() => {
        const fetchProject = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(`https://api.infinitypad.com/project/${slug}/`);
                const data = await response.json();
                setProject(data);
            } catch (error) {
                console.error('Error fetching project:', error);
                setProject([]);

            }
            setIsLoading(false);
        };

        fetchProject();
    }, [slug]);

    const socialMediaLinks = project ? {
        twitter_url: project.twitter_url,
        telegram_url: project.telegram_url,
        medium_url: project.medium_url,
        discord_url: project.discord_url,
        youtube_url: project.youtube_url,
        reddit_url: project.reddit_url, // Handles null values gracefully
        github_url: project.github_url, // Handles null values gracefully
        linkedin_url: project.linkedin_url,
    } : {}; // If project is not yet loaded, use an empty object

    const renderTabContent = () => {
        switch (selectedTab) {
            case 0:
                return <div dangerouslySetInnerHTML={{ __html: project?.overview }}></div>;
            case 1:
                return <div dangerouslySetInnerHTML={{ __html: project?.teamPartner }}></div>;

            case 2:
                return <div dangerouslySetInnerHTML={{ __html: project?.offerings }}></div>;
            default:
                return null;
        }
    };

    if (isLoading) {
        return <Loader />;
    }

    if (!project) {
        return <div>Project not found</div>;
    }

    return (
        <div className='inner-page project-grid-5'>

            <section className="page-title">    <div className="overlay" style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                background: `url('${project.project_hero_image}'), center center no-repeat`,
                backgroundSize: 'cover',
                opacity: 0.15
            }}></div>
                <div className="container" style={{ zIndex: 2 }}> {/* Ensure the container and its contents are above the overlay */}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumbs" data-aos="zoom-in" data-aos-duration="800">
                                <h1>{project.title}</h1>
                                <div className="socials-wrapper">
                                    <Socials socials={socialMediaLinks} />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <br></br>
            <br></br>


            <div className="col-md-12">
                <div className="flat-tabs" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <Tabs selectedIndex={selectedTab} onSelect={index => setSelectedTab(index)}>
                        <div className="wrapper_menutab">
                            <TabList className="menu-tab" style={{ justifyContent: 'center', display: 'flex', flexWrap: 'wrap' }}>
                                {dataTab.map((tab, index) => (
                                    <Tab className='fs-14 h6' key={index} style={{ margin: '0 10px', flex: '0 1 auto' }}>{tab.title}</Tab>
                                ))}
                            </TabList>
                        </div>
                        {dataTab.map((tab, index) => (
                            <TabPanel key={index} className='content-tab mt40'>
                                {/* Dynamic content will be rendered here based on the selected tab */}
                            </TabPanel>
                        ))}
                    </Tabs>
                </div>
            </div>
            <section className="tf-section details">
                <div className="container">
                    <div className="row">
                        <div className="sidebar">
                            <div className="prodect-content">
                                {renderTabContent()}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}

export default ProjectDetails;
