import React, { useEffect, useState } from 'react';

import './styles.scss'

import { Link } from 'react-router-dom';
import Socials from '../socials';

import {
    faXTwitter,
    faTelegramPlane,
    faMediumM,
    faDiscord,
    faYoutube,
    faRedditAlien,
    faInstagram,
    faGithub,
    faLinkedin,
  } from '@fortawesome/free-brands-svg-icons';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Footer(props) {

    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
  
    useEffect(() => {
      const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      window.addEventListener("scroll", toggleVisibility);
  
      return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);
    return (
        <footer id="footer">
        <div className="footer-bottom">
            <div className="container">
                <div className="wrap-fx">
                    <div className="Copyright">
                        Copyright © 2024 Infinity Pad
                    </div>
                    <ul className="social">
                        <a href='https://x.com/Realinfinitypad' target='_blank'><FontAwesomeIcon icon={faXTwitter} size="3x" /></a>
                        <a href='https://t.me/ipadchat' target='_blank'><FontAwesomeIcon icon={faTelegramPlane} size="3x" /></a>
                        <a href='https://medium.com/infinity-pad' target='_blank'><FontAwesomeIcon icon={faMediumM} size="3x" /></a>
                    </ul>
                </div>
            </div>
            
        </div>

        {
                isVisible && 
                <Link onClick={scrollToTop}  to='#' id="scroll-top"></Link>
            }
    </footer>
    );
}

export default Footer;