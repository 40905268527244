import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faTelegramPlane, faMediumM } from '@fortawesome/free-brands-svg-icons';
import './styles.scss';
import logo from '../../assets/images/logo/logo.png';

const Header = () => {

    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 300);
        });
        return () => {
            setScroll({});
        }
    }, []);

    const [menuActive, setMenuActive] = useState(null);

    const handleMenuActive = () => {
        setMenuActive(!menuActive);
    };

    const handleMenuToggle = () => {
        setMenuActive(!menuActive);
    };

    const [activeIndex, setActiveIndex] = useState(null);
    const handleDropdown = index => {
        setActiveIndex(index);
    };

    return (
        <header id="header_main" className={`header is-fixed`}>
            <div className="container">
                <div id="site-header-inner">
                    <div className="header__logo">
                        <NavLink to="/"><img src={logo} alt="Infinity Pad" /></NavLink>
                    </div>
                    <div className={`mobile-button ${menuActive ? 'active' : ''}`} onClick={handleMenuActive}><span></span></div>

                    <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : ''}`} >
                        <ul id="menu-primary-menu" className="menu">
                            <li className="menu-item">
                                <NavLink to="https://staking.infinitypad.com/" target='blank'>Staking</NavLink>
                            </li>
                            <li className="menu-item">
                                <NavLink to="https://faq.infinitypad.com/" target='blank'>FAQs</NavLink>
                            </li>

                            <li className="menu-item">
                                <NavLink to="https://x.com/Realinfinitypad" target='blank'><FontAwesomeIcon icon={faXTwitter} size='2x'/></NavLink>
                            </li>
                            <li className="menu-item">
                                <NavLink to="https://t.me/ipadchat" target='blank'><FontAwesomeIcon icon={faTelegramPlane} size='2x' /></NavLink>
                            </li>
                            <li className="menu-item">
                                <NavLink to="https://medium.com/infinity-pad" target='blank'><FontAwesomeIcon icon={faMediumM}  size='2x'/></NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    );
}

export default Header;