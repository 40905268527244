import React from 'react';

function PageTitle() {
    return (
        <section className="page-title">
            <div className="overlay"></div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                            <div className="breadcrumbs" data-aos="zoom-in" data-aos-duration="800">
                                <h1>Discover IPAD Launchpad</h1>
                                <br></br>
                                <h5>Get exclusive access to tomorrow's top projects with our <a href='https://faq.infinitypad.com/'><u>Safeguard Policy</u></a> and strict project selection.</h5>
                            </div>            
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PageTitle;