import dataBanner from '../assets/fake-data/data-banner';
import React from 'react';
import '../scss/component/_section.scss'; 
import '../scss/component/_box.scss'; 
import '../scss/component/_tf-section.scss'; 
import '../scss/component/_reponsive.scss'; 
import CompletedSales from '../components/project/completed_sales';
import OngoingSales from '../components/project/ongoing_sales';  
import UpcomingSales from '../components/project/upcoming_sales';
import dataStep from '../assets/fake-data/data-step';
import Step from '../components/Step/Step';
import PageTitle from '../components/pagetitle';

function HomeOne({ data, onItemClick }) {
    return (
        <div className='inner-page'>
            {<PageTitle title='Contact' />}

            {/* {<Step data={dataStep} />} */}
           {/* <OngoingSales data={data.featured} onItemClick={onItemClick} />
           <UpcomingSales data={data.upcoming} onItemClick={onItemClick} /> */}
           <CompletedSales data={data}/>
            
        </div>
    );

}

export default HomeOne;

