import React from 'react';
import './styles.scss';
import logo from '../../assets/images/loader.png';

const Loader = () => {
  return (
    <div id="loader-overlay" className="loaderOverlay">
    <div className="loaderContent">
      <img
        id="loader-image"
        src={logo}
        alt="Loading..."
        className="loaderImage"
      />
      <div className="loaderCircle"></div>
    </div>
  </div>
);
};

export default Loader;
